'use client';

import { AskableUIProvider } from 'ui';

import { NextThemeProvider } from '@/context/NextThemeProvider/NextThemeProvider';
import { useSetColorMode } from '@/hooks/useSetColorMode/useSetColorMode';

import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const LayoutInner = ({ children }: Props) => {
  useSetColorMode('dark');
  return children;
};

const Layout = ({ children }: Props) => {
  return (
    <NextThemeProvider>
      {/* Still relies on many legacy ui library */}
      <AskableUIProvider>
        <LayoutInner>{children}</LayoutInner>
      </AskableUIProvider>
    </NextThemeProvider>
  );
};

export default Layout;
