import { useTheme } from 'next-themes';
import { useEffect } from 'react';
import { useColorMode } from 'ui';

import type { Theme } from '@askable/ui/types';
import type { ColorMode } from 'ui';

export function useSetColorMode(color: ColorMode | Theme) {
  const cm = useColorMode();
  const { setTheme } = useTheme();
  useEffect(() => {
    setTheme(color);

    if (cm.colorMode === color) {
      return;
    }

    if (cm?.setColorMode) {
      cm.setColorMode(color);
    }
  }, [color, cm]);
}
